import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const Investment = () => {
    const [readMore, setReadMore] = useState(false)

    return (
        <StyledInvestment>
            <TextWrapper>
                <H3>Medad Technology is joining the digital shift in the FinTech sector through an investment
                    in <Link to={'http://aiondigital.com/'} target={'_blank'}><Span>Aion Digital.</Span></Link></H3>
                <Description>
                    <P>Aion Digital is opening new digital banks in the GCC and Asia. Through machine learning, AI, and
                        advanced data processing, Aion Digital provides digital-ready banking customers with an engagement model built around their lifestyle.</P>
                    <ShowMoreButton showMore={readMore} onClick={() => setReadMore(true)}>Show more</ShowMoreButton>
                    <ShowMore showMore={readMore}>
                    <P>The retail banking model makes managing personal finances easier by automating much of the
                        banking
                        process. People engage with their banking app through a seamless customer journey featuring
                        intelligent
                        summary views, convenient access through multiple channels, and full use of a spectrum of open
                        banking
                        possibilities.</P>
                    <P>Corporate banking is also being revolutionized through digital transformation. Previously,
                        corporate
                        banks invested heavily in static and costly IT infrastructure. Through Aion Digital newer
                        ‘no-code’
                        and
                        ‘low-code’ additions to these systems can enhance banking capabilities immediately without
                        expensive
                        infrastructure rebuilding.</P>
                    <P>Our commitment to human centricity and innovation drives our partnership with Aion Digital. We
                        want
                        to
                        improve quality of life for a digital world in one of the most fundamental aspects of society:
                        money
                        and
                        trade. Through open banking digitalization and automation, modern banking can continue to become
                        fairer
                        and accessible to everyone.</P>
                    </ShowMore>
                </Description>
            </TextWrapper>
        </StyledInvestment>
    )
}

export default Investment

const StyledInvestment = styled.div`
  padding: 9.4rem 12rem 0 12rem;
  color: #0C2637;
  background: rgb(255,255,255);
  background: linear-gradient(11deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.2) 69%, rgba(149,144,217,0.5) 81%);
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 6rem 2.4rem 3rem 2.4rem;
    background: rgb(255,255,255);
    background: linear-gradient(11deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.05) 69%, rgba(149,144,217,0.3) 81%);
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 9.4rem 15vw 0rem 15vw;
  }
`

const TextWrapper = styled.div`
`

const Description = styled.div`
  margin-top: 4rem;
  
  display: flex;
  flex-direction: column;
  
  &:first-child {
    margin-top: 0;
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2.8rem;
  }
`

const ShowMoreButton = styled.button`
  margin-top: 2rem;
  display: none;
  text-decoration: none;
  border: none;
  background: none;
  color: #3B869D;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: ${({ showMore }) => showMore ? 'none' : 'flex'};
  }
`

const ShowMore = styled.div`
    @media ${({ theme }) => theme.breakpoints.mobile} {
      display: ${({ showMore }) => showMore ? 'block' : 'none'};
    }
`

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-family: Sequel75;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`
const Span = styled.span`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  color: #3B869D;
  text-decoration: underline solid #3B869D .3rem;
  text-underline-offset: 1rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: block;
    font-family: Sequel75;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-decoration: underline solid #3B869D .2rem;
    text-underline-offset: .7rem;
  }
`

const P = styled.p`
  font-family: Sequel45;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin-top: 3rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2.4rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`
