import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../../components/fintech-page/hero/Hero.js";
import Layout from '../../components/layout/Layout'
import Seo from '../../components/seo'
import Sonochemistry from "../../components/healthtech-page/sonochemistry/Sonochemistry";
import Applications from "../../components/healthtech-page/applications/Applications";
import Security from "../../components/healthtech-page/security/Security";
import OtherSectors from "../../components/healthtech-page/other-sectors/OtherSectors";
import Investment from "../../components/fintech-page/investment/Investment";

const FintechPage = (props) => {
    const {strapiFintech} = useStaticQuery(query);
    return (
        <Layout>
            <Seo
                title={strapiFintech.metaTitle}
                description={strapiFintech.metaDescription}
                keywords={strapiFintech.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <Investment />
            <OtherSectors hiddenIndex={3} />
        </Layout>
    )
}

export default FintechPage

const query = graphql`
    query {
        strapiFintech {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
